<template>
    <ValidationObserver ref="form">
        <form @submit.prevent="submit">
            <b-card header="Forgot Password" header-bg-variant="primary" header-text-variant="white" class="card-fp">
                <ValidationProvider :rules="{required: true}" name="Email Address">
                    <b-form-group label="Email Address" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.Email" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
                <b-button type="submit" block variant="outline-primary" :disabled="loading">Submit</b-button>
                <div class="text-danger text-center mt-3">{{error}}</div>
            </b-card>
        </form>
    </ValidationObserver>
</template>
<script>
    export default {
        props: {
            nextUrl: {
                default: '/'
            }
        },
        data() {
            return {
                form: {},
                loading: false,
                error: null,
            };
        },
        methods: {
            async submit() {
                const valid = await this.$refs.form.validate();
                if (valid) {
                    this.error = null,
                    this.loading = true;
                    await this.$remote.request('Identity', 'ForgotPassword', this.form);
                    await this.$bvToast.toast('Successfully submitted request. Please check your email.', {
                        title: 'Submitted',
                        variant: 'success',
                        toaster: 'b-toaster-bottom-center',
                        solid: true,
                        appendToast: true,
                    });
                    this.loading = false;
                    this.$router.push('/login');
                }
            },
        }
    }
</script>
<style scoped>
    .card-fp {
        max-width: 400px;
        position: fixed;
        top: 40%;
        left: 55%;
        margin-top: -100px;
        margin-left: -200px;
    }
</style>